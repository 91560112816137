import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "preview", "filename", "replace"];
    static values = { thumbnailUrl: String, defaultThumbnail: String };

    connect() {
        this.initializePreview();
        console.log("Connected");
        console.log("Default Thumbnail Value: ", this.defaultThumbnailValue);
        console.log("Image Plus Value", this.previewTarget.dataset.fileUploadDefaultIconPathValue);
        console.log("Thumbnail Value", this.thumbnailUrlValue);
    }

    initializePreview() {
        // If the thumbnail is not the default thumbnail, show the image and the Replace button
        if (this.hasThumbnailUrlValue && this.thumbnailUrlValue !== '' && this.thumbnailUrlValue !== this.defaultThumbnailValue) {
            this.previewTarget.src = this.thumbnailUrlValue;
            this.filenameTarget.classList.add('hidden');  // Hide the "Click to upload image" text
            this.replaceTarget.classList.remove('hidden');  // Show the "Replace" button
        } else {
            // If it's the default thumbnail, show the image-plus icon and the upload option
            this.previewTarget.src = this.defaultThumbnailValue;
            this.filenameTarget.classList.remove('hidden');  // Show the "Click to upload image" text
            this.replaceTarget.classList.add('hidden');  // Hide the "Replace" button
        }
    }

    updateUI() {
        const file = this.inputTarget.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.previewTarget.src = e.target.result;
            };
            reader.readAsDataURL(file);

            this.filenameTarget.classList.add('hidden');  // Hide the "Click to upload image" text
            this.replaceTarget.classList.remove('hidden');  // Show the "Replace" button
        } else {
            this.initializePreview();  // Reset to the default or thumbnail if no file is selected
        }
    }

    replaceImage() {
        this.inputTarget.value = ''; // Clear the file input
        this.previewTarget.src = this.previewTarget.dataset.fileUploadDefaultIconPathValue; // Reset to the default icon
        this.filenameTarget.classList.remove('hidden');  // Show the "Click to upload image" text
        this.replaceTarget.classList.add('hidden');
    }
}