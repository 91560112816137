import { Controller } from "@hotwired/stimulus";

document.addEventListener("DOMContentLoaded", function() {
    const searchInput = document.querySelector("#search");
    if (searchInput) {
        searchInput.value = "";
    }
});

export default class extends Controller {
    static targets = ["input"]

    connect() {
        this.timeout = null
    }

    filter() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if (this.inputTarget.value === "") {
                this.submitForm() // Reset and show all events
            } else {
                this.submitForm() // Search as you type
            }
        }, 300)
    }

    submitForm() {
        this.element.requestSubmit()
    }
}