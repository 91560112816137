import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application
// Import and register your SearchController

export { application }
