import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["eventFormsInput", "list"];
    static values = {
        trashIcon: String
    }

    connect() {
        console.log("Value: ", this.trashIconValue)
    }


    triggerFileInput() {
        this.eventFormsInputTarget.click();
    }

    handleFiles() {
        this.updateFileList();
    }

    updateFileList() {
        const files = this.eventFormsInputTarget.files;
        this.listTarget.innerHTML = "";

        [...files].forEach(file => {
            if (file.type === "application/pdf") {
                const listItem = document.createElement('li');
                listItem.classList.add('flex', 'justify-between', 'items-center', 'bg-white', 'p-2', 'border', 'border-gray-200', 'rounded-lg', 'shadow-sm');

                const fileNameSpan = document.createElement('span');
                fileNameSpan.textContent = file.name;
                fileNameSpan.classList.add('text-gray-700');

                const removeButton = document.createElement('span');
                removeButton.innerHTML = `<img src="${this.trashIconValue}" alt="Remove file" class="w-5 h-5 cursor-pointer">`;
                removeButton.classList.add('remove-file', 'cursor-pointer', 'text-red-600', 'ml-4');
                removeButton.addEventListener('click', () => {
                    this.removeFile(file);
                    listItem.remove();
                });

                listItem.appendChild(fileNameSpan);
                listItem.appendChild(removeButton);
                this.listTarget.appendChild(listItem);
            } else {
                alert('Only PDF files are allowed.');
            }
        });
    }

    removeFile(fileToRemove) {
        const dataTransfer = new DataTransfer();
        const files = this.eventFormsInputTarget.files;

        [...files].forEach(file => {
            if (file !== fileToRemove) {
                dataTransfer.items.add(file);
            }
        });

        this.eventFormsInputTarget.files = dataTransfer.files;
    }
}