import { Controller } from "@hotwired/stimulus";

let debounceTimeout;

function debounce(callback, delay) {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(callback, delay);
}

export default class extends Controller {
    static targets = ["checkbox", "button"]

    connect() {
        console.log("Connected to tag-filter controller");
        this.toggleButtonClass(); // Run the toggle function when the controller connects
    }

    filter() {
        debounce(() => {
            this.toggleButtonClass(); // Ensure class is toggled on every filter action
        }, 300);  // 300ms debounce delay
    }

    toggleButtonClass() {
        const anyChecked = this.checkboxTargets.some(checkbox => checkbox.checked);
        console.log("Any checkboxes checked?", anyChecked);  // Debugging log
        if (anyChecked) {
            console.log("Adding active class to button");
            this.buttonTarget.classList.add("tag-active-class");  // Ensure "active-class" is defined in your styles
        } else {
            console.log("Removing active class from button");
            this.buttonTarget.classList.remove("tag-active-class");
        }
    }
}
